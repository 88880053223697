@font-face {
  font-family: "HPSimplified";
  src: url("/fonts/HPSimplified_Rg.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "HPSimplified";
  src: url("/fonts/HPSimplified_It.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "HPSimplified";
  src: url("/fonts/HPSimplified_Lt.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "HPSimplified";
  src: url("/fonts/HPSimplified_Bd.ttf") format("truetype");
  font-weight: bold;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a,
a:visited {
  color: #4f93fb;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #1a1a1a;
  }
}
